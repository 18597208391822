<template>
    <b-container fluid="sm">
        <div class="breadcrumb">
                <b-card class="shadow" id="fadein">
                    <b-card-title>
                        <h2>遗忘密码</h2>
                    </b-card-title>
                    <b-card-body>
                        <b-row class>
                            已经有了验证码? 直接在本页面填写邮箱、要修改的密码、验证码完成操作！
                        </b-row>
                        <b-overlay :show="loading" rounded="sm">
                        <b-form>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="邮箱地址">
                                    <b-form-input
                                        type="email"
                                        v-model="email"
                                        :state="validate_email"
                                        placeholder="请填写登录战地用的邮箱, 该邮箱将作为你登录网站的用户名"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        请输入有效的邮箱地址...
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="x新的密码">
                                    <b-form-input
                                        v-model="password_for_validate"
                                        type="password"
                                        :state="validate_password"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        <b>密码必须满足以下条件：长度为8-16个字符，至少包括一个大写字母，一个小写字母，一个数字，一个英文符号->（!@#$%^&*()）</b>
                                        <br />
                                        <b>另: 不建议与其他网站的密码相同</b>
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="重复密码">
                                    <b-form-input
                                        v-model="password_2_for_validate"
                                        :state="validate_correction"
                                        type="password"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        密码不一致
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="邮箱验证码">
                                    <b-form-input
                                        v-model="email_code"
                                        required
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="primary" v-on:click="request_email" :disabled="email_cooldown > 0">
                                            {{email_cooldown_button()}}
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3" v-if="google_code === ''">
                                <vue-recaptcha sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                                               recaptchaHost="www.recaptcha.net"
                                               @verify="verify_google_feedback"
                                               ref="recaptcha_register"
                                               id="resized"/>
                            </b-form-row>
                            <b-button class="mt-3" variant="primary" v-on:click="do_password_reset" :disabled="email_code === ''">修改密码!</b-button>
                        </b-form>
                        </b-overlay>
                    </b-card-body>
                </b-card>
        </div>
    </b-container>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {request} from '@/api'

export default {
    name: "PageRegister",
    components: { VueRecaptcha },
    data() {
        return {
            disable_email_send_button: false,
            email_cooldown: 0,
            show: true,
            password_for_validate: '',
            password_2_for_validate: '',
            email: '',
            email_code: '',
            google_code: '',
            validating: false,
            loading: false
        }
    },
    methods: {
        email_cooldown_button() {
            return this.email_cooldown > 0 ? `请等待 ${this.email_cooldown} 秒` : '获取验证码';
        },
        async request_email() {
            if (this.google_code === '') {
                await this.$bvModal.msgBoxOk("请先通过谷歌验证（就在邮箱验证码的下面）！")
                return
            }
            if (!(this.validate_correction && this.validate_email)) {
                await this.$bvModal.msgBoxOk("有一个或数个信息填写有误, 请检查后提交!")
                return
            }
            this.email_cooldown = 120
            const interval = setInterval(() => {
                this.email_cooldown--;
                if (this.email_cooldown === 0) {
                    clearInterval(interval);
                }
            }, 1000);
            this.loading = true
            await request("POST", "/user/forgotten_password", {
                email: this.email,
                new_password: this.password_for_validate,
                captcha: this.google_code,
            }).then(() => {
                this.$bvModal.msgBoxOk("邮件已经发送! 接收邮件后请正确填写验证码!")
                this.google_code = ''
            }).catch(e => {
                this.$bvModal.msgBoxOk(e[1])
                this.google_code = ''
                this.email_cooldown = 0
                this.$refs.recaptcha_register.reset()
            }).finally(() => {
                this.loading = false
            })

        },
        async do_password_reset() {
            this.loading = true
            this.validating = true
            await request("POST", "/user/forgotten_password", {
                email: this.email,
                new_password: this.password_for_validate,
                token: this.email_code,
                captcha: this.google_code
            }).then(() => {
                this.$bvModal.msgBoxOk("密码修改完成!")
            }).catch(e => {
                this.$bvModal.msgBoxOk(e[1])
                this.google_code = ''
                this.email_cooldown = 0
                this.$refs.recaptcha_register.reset()
            })
            this.validating = false
            this.loading = false
        },
        verify_google_feedback(code) {
            this.google_code = code
        },

    },
    computed: {
        validate_password() {
            const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/;
            return regex.test(this.password_for_validate);
        },
        validate_correction() {
            return this.password_for_validate === this.password_2_for_validate && this.password_for_validate !== '';
        },
        validate_email() {
            // \w*@\w+(\.\w)+
            const regex = /^\w*@\w+(\.\w)+/;
            return regex.test(this.email);
        }
    }
}
</script>

<style scoped>

</style>