<template>
    <div>
        <PageHeader v-if="isHeaderAlive"/>
        <b-container :class="{ 'px-1': screenWidth <= 576 }">
            <b-row class="mt-3">
                <keep-alive>
                    <router-view v-if="isRouterAlive"/>
                </keep-alive>
            </b-row>
            <b-row class="mt-4">
                <PageFooter />
            </b-row>
        </b-container>
        
        <!-- 浮动举报按钮 (不在案例详情页时显示) -->
        <div class="floating-report-btn" v-if="!isInCaseDetailPage" @click="showMobileReport = true">
            <i class="fas fa-flag"></i>
        </div>
        
        <!-- 举报模态框 -->
        <b-modal v-model="showMobileReport" hide-footer title="举报" size="lg">
            <SubmitReport @submit_successful="showMobileReport = false"/>
        </b-modal>
    </div>

</template>

<script>
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import SubmitReport from '@/components/SubmitReport'

export default {
    name: 'App',
    components: {
      PageHeader,
      PageFooter,
      SubmitReport
    },
    provide() {
        return {
            header_reload: this.header_reload,
            route_reload: this.route_reload,
            reload: this.reload
        }
    },
    data() {
      return {
        isHeaderAlive: true,
        isRouterAlive: true,
        screenWidth: window.innerWidth,
        showMobileReport: false
      }
    },
    computed: {
        // 判断当前是否在案例详情页
        isInCaseDetailPage() {
            // 检查路由路径是否匹配案例详情页
            return this.$route && this.$route.name === 'case';
        }
    },
    methods: {
        header_reload() {
            this.isHeaderAlive = false
            this.$nextTick(() => {
                this.isHeaderAlive = true
            })
        },
        route_reload() {
            this.isRouterAlive = false
            this.$nextTick(() => {
                this.isRouterAlive = true
            })
        },
        reload() {
            this.header_reload()
            this.route_reload()
        },
        handleResize() {
            this.screenWidth = document.body.clientWidth
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.custom-breadcrumb {
    background-color: #f8f9fa;
}
.custom-fox {
    background-color: #e9ecef;
}
.sticky-breadcrumb {
    position: sticky;
    top: 0;
    z-index: 1020; /* 高于页面大部分元素 */
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee; /* 添加底部边框 */
}

/* 全局移除所有bootstrap-vue下拉箭头 */
.dropdown-toggle::after {
    display: none !important;
}

/* 也移除其他可能的下拉箭头样式变体 */
.b-dropdown-toggle::after,
button.dropdown-toggle::after,
.btn.dropdown-toggle::after {
    display: none !important;
}

/* 浮动举报按钮样式 */
.floating-report-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1030;
    cursor: pointer;
    transition: all 0.3s ease;
}

.floating-report-btn:hover {
    background-color: #0069d9;
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

.floating-report-btn i {
    font-size: 20px;
}

/* 添加适配手机端的样式 */
@media (max-width: 576px) {
    .container {
        max-width: 100%;
    }
    .row {
        margin-right: -5px;
        margin-left: -5px;
    }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9,
    .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4,
    .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10,
    .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8,
    .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3,
    .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col, .col-1, .col-10,
    .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-xl,
    .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6,
    .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        padding-right: 5px;
        padding-left: 5px;
    }
    .card {
        margin-bottom: 10px;
    }
    .card-body {
        padding: 0.75rem;
    }
    .form-group {
        margin-bottom: 0.5rem;
    }
}
</style>

