<template>
    <div>
        <b-button v-if="showTop" variant="primary" @click="scrollToTop" class="scroll-to-button scroll-btn-top" style="bottom: 80px;">
            <i class="fas fa-arrow-up"></i>
        </b-button>
        <b-button v-if="showBottom" variant="secondary" @click="scrollToBottom" class="scroll-to-button scroll-btn-bottom" style="bottom: 140px;">
            <i class="fas fa-arrow-down"></i>
        </b-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showTop: false,
            showBottom: true
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const totalHeight = document.body.scrollHeight;
            const windowHeight = window.innerHeight;
            if (windowHeight * 2 < totalHeight) {
                // 判断当前滚动位置是在页面的上半部分还是下半部分
                if (scrollPosition > totalHeight / 2) {
                    this.showTop = true;
                    this.showBottom = false;
                } else {
                    this.showTop = false;
                    this.showBottom = true;
                }
            } else {
                this.showTop = false;
                this.showBottom = false;
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        scrollToBottom() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style>
.scroll-to-button {
    position: fixed;
    right: 20px;
    z-index: 999;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    opacity: 0.8;
    transition: all 0.3s ease;
}

.scroll-to-button:hover {
    transform: scale(1.1);
    opacity: 1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.scroll-to-button i {
    font-size: 18px;
}

.scroll-btn-top:hover {
    animation: pulse-up 1s infinite;
}

.scroll-btn-bottom:hover {
    animation: pulse-down 1s infinite;
}

@keyframes pulse-up {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes pulse-down {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(0);
    }
}
</style>