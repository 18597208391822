<template>
    <div id="adminPage">
        <b-card class="mt-4 shadow-sm">
            <b-card-title class="text-primary">
                <i class="fas fa-reply-all mr-2"></i>回复与处理
            </b-card-title>
            <b-card-body>
                <b-form-checkbox
                    v-if="appeal_allowed === true"
                    id="checkbox-1"
                    v-model="appeal"
                    name="checkbox-1"
                    :value=true
                    :unchecked-value=false
                    class="mb-3 appeal-checkbox"
                >
                    <span class="appeal-text"><i class="fas fa-gavel mr-1"></i>将本次回复作为申诉。您也可以前往Q群或使用邮箱申诉（具体见顶部“关于”内）</span>
                </b-form-checkbox>
                <b-form-group label-class="font-weight-bold" label="处理方式:" v-if="perm_level >= 16" class="mb-3">
                    <b-select v-model="selected"
                              :options="[
                              { text: '仅回复', value: -1},
                              { text: '封禁', value: 1},
                              { text: '无效举报', value: 2 },
                              { text: '自证通过', value: 3 },
                              { text: '自证', value: 4 },
                              { text: '刷枪', value: 5},
                              { text: '需要重判', value: -100},
                              { text: '更换举报板块', value: -1000}
                          ]"
                          class="custom-select"
                    />
                </b-form-group>
                <b-form-group v-show="selected === -1000 && perm_level >= 16" class="mb-3">
                    <b-select v-model="selected_resend"
                              :options="[
                                  {text: '战地1', value: '1'},
                                  {text: '战地5', value: '5'},
                                  {text: '其他分类', value: '0'},
                              ]"
                              class="custom-select"
                    >
                    </b-select>
                </b-form-group>
                <b-form-group v-show="selected === 4 && perm_level >= 16" class="mb-3">
                    <b-select v-model="selected_expired"
                              :options="[
                                  {text: '自证时间: 3天', value: '259200'}
                              ]"
                              class="custom-select"
                    >
                    </b-select>
                </b-form-group>
                <MarkedDownInputBox ref="reply_box" />
            </b-card-body>
            <b-card-footer class="text-right bg-light">
                <b-button variant="primary" v-on:click="submit_reply()" class="px-4">
                    <i class="fas fa-paper-plane mr-1"></i>提交
                </b-button>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import MarkedDownInputBox from "@/components/MarkedDownInputBox";
import { request } from '@/api'

export default {
    inject: ['reload'],
    props: ['perm_level', 'appeal_allowed', 'on_reply_complete'],
    data() {
        return {
            selected: -1,
            selected_resend: 0,
            selected_expired: '259200',
            subtype: -1,
            reply: null,
            appeal: false
        }
    },
    methods: {
        async submit_reply() {
            const now = Date.now();
            const lastAppealTime = localStorage.getItem('lastAppealTime');

            if (this.appeal === true) {
                if (lastAppealTime && now - lastAppealTime < 5 * 60 * 1000) {
                    // 如果距离上一次申诉的时间小于5分钟
                    await this.$bvModal.msgBoxOk("您的申诉提交过于频繁，请等待5分钟后再尝试。或者修改为 '回复'。", {title: '提示!'});
                    return; // 直接返回，不执行后续代码
                } else {
                    // 更新存储的时间戳
                    localStorage.setItem('lastAppealTime', now.toString());

                    // 弹出确认/取消对话框
                    const confirm = await this.$bvModal.msgBoxConfirm("你勾选了 '申诉' 复选框，目前 '申诉' 功能暂定为五分钟才能使用一次，故建议您一次性地在文本框内尽量多地阐述您申诉的理由（如：刷枪的证据），以便更好地处理您的案件。需要继续提交请点击 '确定', 否则点击 '取消' 返回继续修改", {title: '提示!'});
                    if (!confirm) {
                        return; // 如果用户点击了取消，则直接返回，不继续执行后续的提交逻辑
                    }
                }
            }

            try {
                const reply_value = this.$refs.reply_box.markdown_value
                this.$refs.reply_box.waiting = true  // 显示转圈等待的效果
                if (reply_value === '') {
                    await this.$bvModal.msgBoxOk("未填写任何回复!")
                    return
                }
                if (this.appeal) {
                    await request('POST', '/case/' + this.$route.params.id + "/appeal", {reply: reply_value})
                } else if (this.selected === -100) {
                    await request('POST', '/case/' + this.$route.params.id + "/reset", {reply: reply_value})
                } else if (this.selected === -1000) {
                    await request('POST', '/case/' + this.$route.params.id + "/reset?to=" + this.selected_resend, {reply: reply_value})
                } else if (this.selected === 4) {
                    if (this.selected_expired === 0) {
                        await this.$bvModal.msgBoxOk("无效的自证时长!")
                        return
                    }
                    await request('POST', '/case/' + this.$route.params.id + "/set_appeal_status?expired_time=" + this.selected_expired, {reply: reply_value, type: this.selected})
                } else if (this.selected >= 0) {
                    await request('POST', '/case/' + this.$route.params.id + "/judge", {reply: reply_value, type: this.selected, sub_type: 1})
                } else {
                    await request('POST', '/case/' + this.$route.params.id + "/reply", {reply: reply_value})
                }
                await this.$bvModal.msgBoxOk("回复已经提交!")
                if (this.on_reply_complete) {
                    this.on_reply_complete()
                }
            } catch (e) {
                await this.$bvModal.msgBoxOk(e[1], {title: '警告!'})
            } finally {
                this.$refs.reply_box.waiting = false  // 结束转圈等待
            }
            
        }
    },
    components: {
        MarkedDownInputBox
    }
}
</script>

<style>
.appeal-checkbox label {
    font-weight: 600;
}

.appeal-text {
    color: #dc3545;
    font-weight: 600;
}

.custom-select {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
