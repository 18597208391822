var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"adminPage"}},[_c('b-card',{staticClass:"mt-4 shadow-sm"},[_c('b-card-title',{staticClass:"text-primary"},[_c('i',{staticClass:"fas fa-reply-all mr-2"}),_vm._v("回复与处理 ")]),_c('b-card-body',[(_vm.appeal_allowed === true)?_c('b-form-checkbox',{staticClass:"mb-3 appeal-checkbox",attrs:{"id":"checkbox-1","name":"checkbox-1","value":true,"unchecked-value":false},model:{value:(_vm.appeal),callback:function ($$v) {_vm.appeal=$$v},expression:"appeal"}},[_c('span',{staticClass:"appeal-text"},[_c('i',{staticClass:"fas fa-gavel mr-1"}),_vm._v("将本次回复作为申诉。您也可以前往Q群或使用邮箱申诉（具体见顶部“关于”内）")])]):_vm._e(),(_vm.perm_level >= 16)?_c('b-form-group',{staticClass:"mb-3",attrs:{"label-class":"font-weight-bold","label":"处理方式:"}},[_c('b-select',{staticClass:"custom-select",attrs:{"options":[
                          { text: '仅回复', value: -1},
                          { text: '封禁', value: 1},
                          { text: '无效举报', value: 2 },
                          { text: '自证通过', value: 3 },
                          { text: '自证', value: 4 },
                          { text: '刷枪', value: 5},
                          { text: '需要重判', value: -100},
                          { text: '更换举报板块', value: -1000}
                      ]},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === -1000 && _vm.perm_level >= 16),expression:"selected === -1000 && perm_level >= 16"}],staticClass:"mb-3"},[_c('b-select',{staticClass:"custom-select",attrs:{"options":[
                              {text: '战地1', value: '1'},
                              {text: '战地5', value: '5'},
                              {text: '其他分类', value: '0'} ]},model:{value:(_vm.selected_resend),callback:function ($$v) {_vm.selected_resend=$$v},expression:"selected_resend"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 4 && _vm.perm_level >= 16),expression:"selected === 4 && perm_level >= 16"}],staticClass:"mb-3"},[_c('b-select',{staticClass:"custom-select",attrs:{"options":[
                              {text: '自证时间: 3天', value: '259200'}
                          ]},model:{value:(_vm.selected_expired),callback:function ($$v) {_vm.selected_expired=$$v},expression:"selected_expired"}})],1),_c('MarkedDownInputBox',{ref:"reply_box"})],1),_c('b-card-footer',{staticClass:"text-right bg-light"},[_c('b-button',{staticClass:"px-4",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit_reply()}}},[_c('i',{staticClass:"fas fa-paper-plane mr-1"}),_vm._v("提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }