<template>
    <div>
        <h4>我的收藏 <b-badge variant="info">{{ favourCount }}/100</b-badge></h4>
        <hr>
        <b-row align-h="end" class="mb-3">
            <b-col cols="auto">
                <b-button size="sm" @click="refreshTable()">刷新</b-button>
            </b-col>
        </b-row>

        <b-overlay :show="isLoading" rounded="sm">
            <!-- 卡片式布局 -->
            <div v-if="items.length > 0">
                <div class="case-grid">
                    <div v-for="item in items" :key="item.case_id" class="case-card">
                        <div class="card-inner" @click="goToCase(item.case_id)">
                            <!-- 状态盖章 -->
                            <div class="status-stamp-container">
                                <span 
                                    :class="['status-stamp', 'bg-' + getStatusVariant(item.current_status)]"
                                    :title="getStatusTitle(item.current_status)"
                                >
                                    {{ getStatusTitle(item.current_status) }}
                                </span>
                            </div>
                            
                            <!-- 案件信息 -->
                            <div class="case-info">
                                <div class="case-id">{{ item.case_id }}</div>
                                <div class="target-name">{{ item.target_current_name }}</div>
                                <div class="case-date">{{ new Date(item.created_at).toLocaleString() }}</div>
                                
                                <!-- 备注信息(如果有) -->
                                <div v-if="item.remind" 
                                     class="case-remind" 
                                     :title="item.remind"
                                     @click.stop>
                                    <i class="fas fa-sticky-note mr-1"></i> {{ item.remind }}
                                </div>
                            </div>
                            
                            <div class="card-footer">
                                <b-button size="sm" variant="outline-danger" @click.stop="showDeleteModal(item)">
                                    <i class="fas fa-trash"></i> 取消收藏
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <b-row class="mt-3">
                    <b-col>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalItems"
                            :per-page="perPage"
                            align="center"
                            class="my-0"
                            @change="handlePageChange"
                            v-if="totalItems > perPage"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </div>
            
            <!-- 空状态 -->
            <div v-else-if="!isLoading" class="text-center my-5">
                <h5>暂无收藏记录</h5>
                <p class="text-muted">浏览案件时可以收藏感兴趣的案件</p>
            </div>
        </b-overlay>

        <b-modal id="delete-modal" title="取消收藏" @ok="removeFavour">
            <p>确定要取消收藏这个案件吗？</p>
        </b-modal>
    </div>
</template>

<script>
import { request } from "@/api";

export default {
    name: "UserFavour",
    data() {
        return {
            perPage: 10, // 调整为10个/页
            currentPage: 1,
            favourCount: 0,
            totalItems: 0,
            currentItem: null,
            isLoading: false,
            items: [],
            caseStatusName: {
                "1": {"variant": "danger", "title": "封禁"},
                "2": {"variant": "secondary", "title": "无效举报"},
                "3": {"variant": "success", "title": "自证通过"},
                "4": {"variant": "success", "title": "等待自证完成"},
                "5": {"variant": "secondary", "title": "刷枪"},
                "0": {"variant": "info", "title": "未处理"},
            }
        };
    },
    methods: {
        myProvider() {
            this.isLoading = true;
            const params = {
                pn: this.currentPage,
                size: this.perPage
            };
            
            const queryString = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');
                
            request('GET', `/user/self/favour?${queryString}`)
                .then(response => {
                    if (response.data) {
                        this.items = response.data;
                        // 获取最新的收藏总数
                        this.getFavourCount();
                    } else {
                        this.items = [];
                    }
                })
                .catch(e => {
                    console.error("获取收藏列表失败", e);
                    this.items = [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        
        getStatusVariant(status) {
            const statusKey = status.toString();
            return this.caseStatusName[statusKey] ? this.caseStatusName[statusKey].variant : 'secondary';
        },
        
        getStatusTitle(status) {
            const statusKey = status.toString();
            return this.caseStatusName[statusKey] ? this.caseStatusName[statusKey].title : '未知';
        },
        
        handlePageChange(page) {
            this.currentPage = page;
            this.myProvider();
        },
        
        refreshTable() {
            this.myProvider();
            this.getFavourCount();
        },
        
        getFavourCount() {
            request('GET', '/user/self/favour/num')
                .then(response => {
                    if (response.num) {
                        this.favourCount = response.num;
                        // 使用favourCount作为分页的总数
                        this.totalItems = response.num;
                    } else {
                        this.favourCount = 0;
                        this.totalItems = 0;
                    }
                })
                .catch(e => {
                    console.error("获取收藏数量失败", e);
                    this.favourCount = 0;
                    this.totalItems = 0;
                });
        },
        
        goToCase(caseId) {
            this.$router.push('/case/' + caseId);
        },
        
        showDeleteModal(item) {
            this.currentItem = item;
            this.$bvModal.show('delete-modal');
        },
        
        removeFavour() {
            if (!this.currentItem) return;
            
            request('POST', '/user/self/favour/remove_favour', {
                case_id: this.currentItem.case_id,
                remind: this.currentItem.remind || ""
            })
                .then(() => {
                    this.$bvToast.toast('取消收藏成功', {
                        title: '成功',
                        variant: 'success',
                        solid: true
                    });
                    this.refreshTable();
                })
                .catch(e => {
                    this.$bvToast.toast(`取消收藏失败: ${e}`, {
                        title: '错误',
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    },
    mounted() {
        this.getFavourCount();
        this.myProvider();
    }
};
</script>

<style scoped>
.pagination {
    justify-content: center;
}

.case-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
}

/* 桌面端显示2行5列 */
@media (min-width: 992px) {
    .case-grid {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: minmax(180px, auto);
    }
}

.case-card {
    position: relative;
    margin-bottom: 15px;
}

.card-inner {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.card-inner:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.case-id {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 5px;
    color: #007bff;
}

.target-name {
    font-size: 1.1em;
    margin-bottom: 5px;
}

.case-date {
    font-size: 0.8em;
    color: #6c757d;
    margin-bottom: 10px;
}

.case-remind {
    background-color: #fffbeb;
    padding: 8px;
    border-radius: 4px;
    font-size: 0.9em;
    border-left: 3px solid #ffc107;
    margin-top: auto;
    margin-bottom: 10px;
    position: relative;
    cursor: default;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

/* 手机端备注显示 */
@media (max-width: 768px) {
    .case-remind {
        background-color: #fffbeb;
        padding: 10px;
        border-radius: 4px;
        font-size: 0.9em;
        border-left: 3px solid #ffc107;
        margin-top: auto;
        margin-bottom: 10px;
        word-break: break-word;
    }
}

.card-footer {
    margin-top: auto;
}

.status-stamp-container {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
}

.status-stamp {
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    transform: rotate(15deg);
    font-weight: bold;
    color: white;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
    opacity: 0.9;
    font-size: 1em;
}

.bg-danger {
    background-color: #dc3545;
}

.bg-success {
    background-color: #28a745;
}

.bg-info {
    background-color: #17a2b8;
}

.bg-secondary {
    background-color: #6c757d;
}

.bg-primary {
    background-color: #007bff;
}

.bg-warning {
    background-color: #ffc107;
}
</style> 