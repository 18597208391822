<template>
    <div id="reportPage">
        <b-card :title="screenWidth <= 576 ? '手机端举报' : '举报页面'" :class="screenWidth <= 576 ? 'mobile-card' : ''">
            <b-form-group label="举报ID" class="form-inline">
                <b-form-input v-model="report_EAID" />
            </b-form-group>
            <b-form-group label="游戏" class="form-inline">
                <b-form-radio-group
                    id="game"
                    v-model="game_selected"
                    name="radio-sub-component"
                >
                    <b-form-radio value="1">战地1</b-form-radio>
                    <b-form-radio value="5" class="disabled" disabled>战地5 (人手不足, 开放会通知)</b-form-radio>
                    <b-form-radio value="0" class="disabled" disabled>其他</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            <b-form-group label="理由">
                <div class="mt-3"><MarkedDownInputBox ref="report_reason" toolbars="toolbars"/></div>
            </b-form-group>
            <b-card-footer>
                <b-button variant="outline-primary float-right" v-on:click="submit_report()" class="pull-right">提交</b-button>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import { request} from "@/api";
import MarkedDownInputBox from "@/components/MarkedDownInputBox";
import { is_login } from '@/cookies';

export default {
    name: "SubmitReport",
    data() {
        return {
            report_EAID: null,
            report: null,
            game_selected: 1,
            screenWidth: window.innerWidth
        }
    },
    methods: {
        async submit_report () {
            try {
                // 检查是否已登录
                if (!is_login()) {
                    // 未登录，显示提示并跳转到登录页面
                    await this.$bvModal.msgBoxOk("请先登录后再提交举报!", {title: '提示'});
                    // 显示登录对话框
                    this.$root.$emit('bv::show::modal', 'login_box');
                    return;
                }
                
                this.$refs.report_reason.waiting = true  // 显示转圈等待的效果
                const confirm = await this.$bvModal.msgBoxConfirm("确定要提交本次举报吗?")
                if (!confirm) {
                    return
                }
                if (this.$refs.report_reason.markdown_value.length < 15) {
                    await this.$bvModal.msgBoxOk("举报字数请大于15字!", {title: '失败!'})
                    return
                }
                await request('POST', '/case/new', {
                    target_EAID: this.report_EAID,
                    report_markdown: this.$refs.report_reason.markdown_value, game: this.game_selected
                })
                await this.$bvModal.msgBoxOk("举报已经提交!", {title: '成功!'})
                this.$emit("submit_successful")
            } catch (e) {
                await this.$bvModal.msgBoxOk(e, {title: '警告!'})
            } finally {
                this.$refs.report_reason.waiting = false  // 结束显示
            }
        },
        handleResize() {
            this.screenWidth = document.body.clientWidth
        }
    },
    components: {
        MarkedDownInputBox
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.mobile-card {
    padding: 10px;
    font-size: 14px;
}
.mobile-card .form-inline {
    display: block;
}
.mobile-card .form-inline .form-control {
    width: 100%;
    margin-bottom: 10px;
}
@media (max-width: 576px) {
    .form-inline {
        display: block;
    }
    .form-inline .form-control {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>