<template>
<div id="header" :style="screenWidth <= 576 ? 'margin-top:10px; margin-left: 5px; margin-right: 5px;' : 'margin-top:20px; margin-left: 15px; margin-right: 15px;'">
    <b-navbar type="light" toggleable="sm" variant="light" class="breadcrumb" style="border-radius: 4px !important;" id="fadeinS">

            <b-navbar-brand>
                <img src="./logo-alpha.png" width="30" height="30" class="d-inline-block align-top" alt="">
                BFEAC
            </b-navbar-brand>
            <b-navbar-toggle target="a"></b-navbar-toggle>

            <b-collapse id="a" is-nav>
                <b-navbar-nav>
                    <b-nav-item href="#/">首页</b-nav-item>
                    <b-nav-item href="#/list">封禁列表</b-nav-item>
                    <b-nav-item href="#/about">关于</b-nav-item>
                    <b-nav-item href="#/waterfall" v-if="this.permlevel() >= 16">信息流</b-nav-item>
                    <b-nav-item href="https://space.bilibili.com/1811762468" target="_blank"><i class="fa-brands fa-bilibili" style="font-size: small;"></i>&nbsp;BiliBili</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item v-on:click="$bvModal.show('login_box')" v-if="current_user_details == null" id="login_button" class="auth-btn">
                        <i class="fas fa-sign-in-alt auth-icon"></i> 登录
                    </b-nav-item>
                    <b-nav-item-dropdown v-bind:html="current_user_details['nickname']" v-else right>
                        <template #button-content>
                            <div class="user-dropdown-toggle">
                                <i class="fas fa-user-circle mr-1"></i>
                                <span v-html="current_user_details['nickname']" class="user-nickname"></span>
                                <i class="fas fa-chevron-down ml-1 custom-dropdown-indicator"></i>
                            </div>
                        </template>
                        <b-dropdown-item href="#/user/notify" class="dropdown-item-custom">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <span><i class="fas fa-bell mr-2"></i> 通知</span>
                                <b-badge pill variant="danger" v-if="current_user_details['unread_msg_count'] > 0">{{ current_user_details['unread_msg_count'] }}</b-badge>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item href="#/user/favour" class="dropdown-item-custom">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <span><i class="fas fa-bookmark mr-2"></i> 收藏夹</span>
                                <b-badge pill variant="danger">{{ favourCount }}</b-badge>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="#" v-on:click="$bvModal.show('change_password_box')" class="dropdown-item-custom">
                            <i class="fas fa-key mr-2"></i> 修改密码
                        </b-dropdown-item>
                        <b-dropdown-item href="#/admin" v-if="this.permlevel() >= 255" class="dropdown-item-custom">
                            <i class="fas fa-cog mr-2"></i> 管理员面板
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="#" v-on:click="logout()" class="dropdown-item-custom logout-item">
                            <i class="fas fa-sign-out-alt mr-2"></i> 注销
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item href="#/register" v-if="current_user_details == null" id="register_button" class="auth-btn">
                        <i class="fas fa-user-plus auth-icon"></i> 注册
                    </b-nav-item>
                    <b-nav-form right @submit.prevent="search_case" :class="{'w-100': screenWidth <= 576}">
                        <b-overlay :show="is_searching">
                            <b-form-input class="mr-sm-1" :style="screenWidth <= 576 ? 'width: 100%;' : 'width: 145px;'" placeholder="查询ID..." v-model="search_id" @keyup.enter="search_case" disabled:="is_searching">
                            </b-form-input>
                            <b-button variant="outline-secondary" class="my-2 my-sm-0 border-0" v-on:click="search_case" disabled:="is_searching" right>
                                <b-icon icon="search" aria-hidden="true"></b-icon>
                            </b-button>
                        </b-overlay>
                    </b-nav-form>
                </b-navbar-nav>
            </b-collapse>
    </b-navbar>
    <b-modal id="search_result" title="搜索结果">
        <b href="#" v-for="(item, index) in search_result" :key="index" >Case {{ item['case_id'] }}, 举报时间: {{ item.created_at }}, 受理状态: {{ item.current_status }}</b>
    </b-modal>
    <b-modal id="login_box" title="登录" @ok="login_handle_Ok">
        <b-overlay :show="is_logging" rounded="sm">
            <b-alert :show="login_error != null" variant="danger" refs="login_failed_message">{{ login_error }}</b-alert>
            <b-card-text>
                <b-form>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">邮箱</label>
                        <div class="col-sm-10">
                            <b-form-input v-model="login_email" type="text" class="form-control" required id="login_username"></b-form-input>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">密码</label>
                        <div class="col-sm-10">
                            <b-form-input v-model="login_password" type="password" class="form-control" required id="login_password"></b-form-input>
                        </div>
                    </div>
                    <div>
                        <b-link href="#/forgotten_password" v-on:click="$bvModal.hide('login_box')">忘记密码?</b-link>
                    </div>
                    <vue-recaptcha
                        sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                        recaptchaHost="www.recaptcha.net"
                        @verify="verifyMethod"
                        ref="recaptcha_login" />
                </b-form>
            </b-card-text>
        </b-overlay>
    </b-modal>
    <b-modal id="change_password_box" title="修改密码" @ok="handleOk_change_pw">
        <b-alert :show="change_pw_error != null" variant="danger">{{ change_pw_error }}</b-alert>
        <b-form>
            <div class="form-group row">
                <label for="inputOldPassword" class="col-sm-2 col-form-label">旧密码</label>
                <div class="col-sm-10">
                    <b-form-input v-model="change_pw_old_password" type="text" class="form-control"></b-form-input>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputOldPassword" class="col-sm-2 col-form-label">新密码</label>
                <div class="col-sm-10">
                    <b-form-input v-model="change_pw_new_password_1" type="password" class="form-control"></b-form-input>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputOldPassword" class="col-sm-2 col-form-label">确认密码</label>
                <div class="col-sm-10">
                    <b-form-input v-model="change_pw_new_password_2" type="password" class="form-control"></b-form-input>
                </div>
            </div>
        </b-form>
    </b-modal>
</div>
</template>

<script>
import {is_login, set_permlevel, get_permlevel, set_eauid} from '@/cookies'
import {request} from '@/api'
import { VueRecaptcha } from 'vue-recaptcha';

export default{
    inject: ['reload'],
    components: { VueRecaptcha },
    data() {
        return {
            search_result: [],
            box_title: "",
            box_body: "",
            search_id: "",

            login_email: null,
            login_password: null,
            login_error: null,
            login_code: null,
            code_url: null,
            is_logging: false,
            current_user_details: null,
            change_pw_error: null,
            change_pw_old_password: null,
            change_pw_new_password_1: null,
            change_pw_new_password_2: null,
            permlevel: get_permlevel,
            is_searching: false,
            screenWidth: window.innerWidth,
            username: "",
            favourCount: 0
        }
    },
    methods: {
        async search_case() {
            try {
                this.is_searching = true
                this.search_result = await request('GET', '/case/EAID/' + this.search_id)
                  if (this.search_result.length === 1) {
                      await this.$router.push({name: 'case', params: {id: this.search_result[0]['case_id']}})
                  } else {
                      this.$bvModal.show("search_result")
                  }
            } catch (e) {
                await this.$bvModal.msgBoxOk(e[1], {title: '警告!'})
            }
            this.is_searching = false
        },
        is_login,
        logout(){
            if (is_login()){
                this.current_user_details = null
                request('GET', '/user/logout').then(() => {
                    // eslint-disable-next-line no-import-assign
                    set_permlevel(0)
                    this.$bvModal.msgBoxOk("注销成功!")
                        .then(() => {this.$router.replace('/')})
                })
            }
        },
        login_handle_Ok(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.login_error = null
            if (this.login_code === null) {
                this.login_error = "请进行人机认证! 请等待登录框下方的验证码载入完成!"
                return
            }
            this.is_logging = true
            this.login_submit()
                .then(() => {
                    this.login_error = null
                    this.$nextTick(() => {
                        this.$bvModal.hide('login_box')
                        this.$bvModal.msgBoxOk("登录成功!")
                            .then(() => {this.reload()})
                        })

                })
                .catch(e => {
                    this.login_error = e[1]
                })
                .finally(() => {
                    this.is_logging = false
                    this.$refs.recaptcha_login.reset();
                })
        },
        handleOk_change_pw(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.change_pw_error = null
            if (!(this.change_pw_new_password_2 && this.change_pw_new_password_1 && this.change_pw_old_password)) {
                this.change_pw_error = "输入不完整!"
                return
            }
            if (this.change_pw_new_password_1 !== this.change_pw_new_password_2) {
                this.change_pw_error = "两次密码输入不一致!"
                return
            } else if (this.change_pw_new_password_1.length < 6 || this.change_pw_new_password_1.length > 16) {
                this.change_pw_error = "密码不符合安全要求! 位数需要在6~16位!"
                return
            } else if (this.change_pw_new_password_1 === this.change_pw_old_password) {
                this.change_pw_error = "新旧密码不能一样!"
                return
            }
            request('POST', '/user/self/new_password', {
                old_password: this.change_pw_old_password, new_password: this.change_pw_new_password_1
            })
                .then(() => {
                    this.$bvModal.hide('change_password_box')
                    this.$bvModal.msgBoxOk("修改成功! 系统自动注销...")
                        .then(() => {this.logout()})
                })
                .catch(e => {
                    this.change_pw_error = e
                })
        },
        async login_submit() {
            await request('POST', '/user/login', {
                email: this.login_email, password: this.login_password, captcha: this.login_code
            })

        },
        verifyMethod(response){
            this.login_code = response;
        },
        handleResize() {
            this.screenWidth = document.body.clientWidth
        },
        getFavourCount() {
            if (!this.is_login()) return;
            
            console.log("正在获取收藏数量...");
            request('GET', '/user/self/favour/num')
                .then(response => {
                    console.log("收藏数量API响应:", response);
                    if (response && response.num) {
                        this.favourCount = response.num;
                        console.log("设置收藏数量:", this.favourCount);
                    } else {
                        this.favourCount = 0;
                    }
                })
                .catch(e => {
                    console.error("获取收藏数量失败", e);
                    this.favourCount = 0;
                });
        }
    },
    created() {
        if (is_login()) {
            request('GET', '/user/self_info')
                .then(data => {
                    this.$nextTick(() => {
                        this.current_user_details = data
                        set_permlevel(data['perm_level'])
                        set_eauid(data['bind_EA_userId'])
                        
                        // 用户信息加载完成后获取一次收藏数量
                        this.getFavourCount();
                    })
                })
                .catch(() => {
                    this.current_user_details = null
                })
        }
    },
    watch: {
        current_user_details: {
            handler (new_value, old_value){
                console.log(new_value, old_value)
                // 监控未读消息是否在增多.
                if (new_value === null) {
                    return;
                } else if (old_value === null && new_value['unread_msg_count'] === 0) {
                    return;
                } else if (new_value['unread_msg_count'] <= old_value['unread_msg_count']) {
                    return;
                }
                this.$bvToast.toast('您有' + new_value['unread_msg_count'] + '消息未读! 点击以查看', {
                    title: '系统消息',
                    href: '#/user/notify',
                    autoHideDelay: 5000
                })
            },
            immediate: true
        },
        '$route'() {
            // 只在用户打开收藏相关页面时获取收藏数量
            if (this.is_login() && (this.$route.path.includes('/user/favour') || this.$route.path.includes('/case/'))) {
                this.getFavourCount();
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.addEventListener('resize', this.handleResize)
        
        // 不需要在这里重复获取收藏数量，已经在created钩子中获取了
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
@media (max-width: 576px) {
    .navbar {
        padding: 0.5rem 0.75rem;
    }
    .navbar-brand {
        font-size: 1rem;
    }
    .nav-link {
        padding: 0.5rem 0.75rem;
    }
    .navbar-toggler {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
    }
}

/* 美化登录注册按钮 */
.auth-btn {
    margin: 0 5px;
    transition: all 0.3s ease;
    border-radius: 4px;
}

.auth-btn:hover {
    transform: translateY(-2px);
}

.auth-icon {
    margin-right: 5px;
    transition: all 0.3s ease;
}

#login_button {
    background-color: rgba(0, 123, 255, 0.1);
}

#login_button:hover {
    background-color: rgba(0, 123, 255, 0.2);
}

#login_button:hover .auth-icon {
    color: #007bff;
    transform: scale(1.2);
}

#register_button {
    background-color: rgba(40, 167, 69, 0.1);
}

#register_button:hover {
    background-color: rgba(40, 167, 69, 0.2);
}

#register_button:hover .auth-icon {
    color: #28a745;
    transform: scale(1.2);
}

/* 用户下拉菜单样式 */
.user-dropdown-toggle {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.dropdown-item-custom {
    padding: 0.5rem 1rem;
    transition: all 0.2s ease;
}

.dropdown-item-custom:hover {
    background-color: rgba(0, 123, 255, 0.1);
    transform: translateX(5px);
}

.dropdown-item-custom i {
    width: 20px;
    text-align: center;
}

.logout-item {
    color: #dc3545;
}

.logout-item:hover {
    background-color: rgba(220, 53, 69, 0.1);
}

/* Badge样式 */
.badge-pill {
    padding: 0.25em 0.6em;
    font-weight: 600;
    min-width: 22px;
    text-align: center;
}

/* 用户名称与下拉三角优化 */
.user-nickname {
    font-weight: 500;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
}

.dropdown-caret {
    font-size: 0.8em;
    opacity: 0.7;
    transition: transform 0.2s ease;
}

.user-dropdown-toggle:hover .dropdown-caret {
    transform: translateY(2px);
}

.custom-dropdown-indicator {
    font-size: 0.75em;
    transition: transform 0.3s ease;
}

.dropdown.show .custom-dropdown-indicator {
    transform: rotate(180deg);
}


.dropdown-toggle::after {
    display: none !important;
}


.user-dropdown-toggle + .dropdown-toggle::after {
    display: none !important;
}


#__BVID__59__BV_toggle_::after {
    display: none !important;
}
</style>