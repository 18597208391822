<template>
    <div class="container">
        <div>
            <b-card
            title="" sub-title="" class="shadow" id="fadein" style="background-color:whitesmoke"
            img-src="6luMmcpOD2ir5Ky.jpg"
            img-alt="Image"
            img-top>
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>战斗，心无旁骛</h1>
                </b-card-title>
                <b-card-text>
                    作为玩家自发成立的组织，BFEAC致力于为战地带来良好的游戏环境。
                    <router-link to="/about" style="align-items: flex-end;"><i class="fa-solid fa-circle-info"></i>&nbsp;了解更多</router-link>
                </b-card-text>
                
            </b-card>
        </div>
        <br />
        <div>
            <b-card class="shadow" id="fadein" style="background-color:whitesmoke">
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>BFEAC 2025春季管理员扩编公告</h1>
                </b-card-title>

                <b-card-text>

                    <h3>我们正在进行2025年春季管理员扩编，欢迎有意向报名者进行报名</h3>

                    <p>报名问卷链接如下：<a href="https://wj.qq.com/s2/18874994/48e1/" title="BFEAC实习管理员初试申请表" target="_blank"> BFEAC实习管理员初试申请表 </a> </p>

                    <p>问卷开放时间为北京时间 <strong>2025年3月21日20：00</strong> 至 <strong>2025年3月26日23：59</strong>。全表由基本信息收集与数个问答题组成，目的为收集信息、确认基础能力及收集意见。预计填写时间不大于15分钟。</p>

                    <P>其他详细信息请见问卷前介绍。感谢您对BFEAC的垂青与支持。</P>


                    
                </b-card-text>
            </b-card>
        </div>
        <br />
        <div>
            <b-card title="" sub-title="" class="shadow" id="fadein">
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>踢出公示</h1>
                </b-card-title>
                <PageKickedLog />
            </b-card>
        </div>
    </div>
</template>

<script>
import PageKickedLog from "@/components/PageKickedLog";

export default {
    components: {
      PageKickedLog
    }
}


</script>