<template>
    <b-container fluid="sm">
        <div class="breadcrumb">
                <b-card class="shadow" id="fadein">
                    <b-card-title>
                        <h2>注册</h2>
                    </b-card-title>
                    <b-card-body>
                        <b-row class>
                            已经有了验证码? <b v-on:mouseup.stop="$bvModal.show('code')" class="text-info" href="#">点击这里立刻开始验证!</b>
                        </b-row>
                        <b-row class="mt-3">
                            <b>公告：</b>
                            <p>如果你的账号并未通过基于EA信息界面的认证导致无法注册，可以在本界面重新注册账号。</p>
                        </b-row>
                        <b-overlay :show="loading" rounded="sm">
                        <b-form>

                            <b-form-row class="mt-3">
                                <b-input-group prepend="邮箱地址">
                                    <b-form-input
                                        type="email"
                                        v-model="email"
                                        :state="validate_email"
                                        placeholder="请填写登录战地用的邮箱, 该邮箱将作为你登录网站的用户名"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        请输入有效的邮箱地址...
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="EAID">
                                    <b-form-input
                                        v-model="EAID"
                                        placeholder="邮箱地址对应账号的EA ID"
                                        required
                                    ></b-form-input>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="网站昵称">
                                    <b-form-input
                                        v-model="nickname"
                                        :state="validate_nickname"
                                        placeholder="网站账户昵称"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        昵称为4-10个字符
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="网站密码">
                                    <b-form-input
                                        v-model="password_for_validate"
                                        type="password"
                                        :state="validate_password"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        <b>密码必须满足以下条件：长度为8-16个字符，至少包括一个大写字母，一个小写字母，一个数字，一个英文符号->（!@#$%^&*()）</b>
                                        <br />
                                        <b>另: 不建议与其他网站的密码相同</b>
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="重复密码">
                                    <b-form-input
                                        v-model="password_2_for_validate"
                                        :state="validate_correction"
                                        type="password"
                                        required
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        密码不一致
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3">
                                <b-input-group prepend="邮箱验证码">
                                    <b-form-input
                                        v-model="email_code"
                                        required
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="primary" v-on:click="pre_register" :disabled="email_cooldown > 0">
                                            {{email_cooldown_button()}}
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-row>
                            <b-form-row class="mt-3" v-if="google_code === ''">
                                <vue-recaptcha
                                    sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                                    recaptchaHost="www.recaptcha.net"
                                    ref="recaptcha_register"
                                    @verify="verify_google_feedback"
                                />
                            </b-form-row>

                            <b-button class="mt-3" variant="primary" v-on:click="do_register" :disabled="email_code === ''">注册!</b-button>
                        </b-form>
                        </b-overlay>
                    </b-card-body>
                </b-card>
        </div>
        <b-modal id="code" title="直接使用验证码..." @ok="do_register">
            <b-overlay :show="validating" rounded="sm">
                <b-card-text>
                    <b-form>
                        <b-form-row class="mt-3">
                            <b-input-group prepend="注册邮箱">
                                <b-form-input
                                    v-model="email"
                                    required
                                ></b-form-input>
                            </b-input-group>
                        </b-form-row>
                        <b-form-row class="mt-3">
                            <b-input-group prepend="验证码">
                                <b-form-input
                                    v-model="email_code"
                                    required
                                ></b-form-input>
                            </b-input-group>
                        </b-form-row>
                    </b-form>
                </b-card-text>
            </b-overlay>
            <template #modal-footer="{ ok, cancel}">
                <b-button variant="danger" @click="cancel()">
                    取消
                </b-button>
                <b-button variant="success" @click="ok()">
                    注册
                </b-button>
            </template>
        </b-modal>
    </b-container>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {request} from '@/api'

export default {
    name: "PageRegister",
    components: { VueRecaptcha },
    data() {
        return {
            nickname: '',
            EAID: '',
            disable_email_send_button: false,
            email_cooldown: 0,
            show: true,
            password_for_validate: '',
            password_2_for_validate: '',
            email: '',
            email_code: '',
            google_code: '',
            validating: false,
            loading: false,
            recaptcha_loading: true
        }
    },
    methods: {
        email_cooldown_button() {
            return this.email_cooldown > 0 ? `请等待 ${this.email_cooldown} 秒` : '获取验证码';
        },
        async pre_register() {
            if (this.google_code === '') {
                await this.$bvModal.msgBoxOk("请先通过谷歌验证（就在邮箱验证码的下面）！")
                return
            }
            if (!(this.validate_nickname && this.validate_password && this.validate_correction && this.validate_email)) {
                await this.$bvModal.msgBoxOk("有一个或数个信息填写有误, 请检查后提交!")
                return
            }
            this.email_cooldown = 120
            const interval = setInterval(() => {
                this.email_cooldown--;
                if (this.email_cooldown === 0) {
                    clearInterval(interval);
                }
            }, 1000);
            this.loading = true
            await request("POST", "/user/request_register_email", {
                email: this.email,
                nickname: this.nickname,
                EAID: this.EAID,
                password: this.password_for_validate,
                captcha: this.google_code,
            }).then(() => {
                this.$bvModal.msgBoxOk("邮件已经发送! 接收邮件后请正确填写验证码!")
            }).catch(e => {
                this.$bvModal.msgBoxOk(e[1])
                this.google_code = ''
                this.email_cooldown = 0
                this.$refs.recaptcha_register.reset()
            }).finally(() => {
                this.loading = false
            })
            this.loading = false
        },
        async do_register() {
            this.loading = true
            this.validating = true
            request('POST', '/user/complete_register', {
                email: this.email,
                token: this.email_code
            }).then(() => {
                this.$bvModal.msgBoxOk("注册成功!")
            }).catch(e => {
                this.$bvModal.msgBoxOk(e[1])
                this.$refs.recaptcha_register.reset()
            }).finally(() => {
                this.loading = false
            })
            this.validating = false
            this.loading = false
        },
        verify_google_feedback(code) {
            this.google_code = code
        },

    },
    computed: {
        validate_nickname() {
            return this.nickname.length >= 4 && this.nickname.length <= 10;
        },
        validate_password() {
            const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/;
            return regex.test(this.password_for_validate);
        },
        validate_correction() {
            return this.password_for_validate === this.password_2_for_validate && this.password_for_validate !== '';
        },
        validate_email() {
            // \w*@\w+(\.\w)+
            const regex = /[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+/;
            return regex.test(this.email);
        }
    }
}
</script>

<style scoped>

</style>