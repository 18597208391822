<template>
    <div class="case-details-wrapper">
        <b-container fluid="sm">
            <b-overlay :show="loading" rounded="sm">
                <!-- 移除固定在顶部的导航和收藏按钮 -->
                <b-card class="custom-fox position-relative">
                    <div class="ml-2 mr-2">
                    <b-card-title class="mt-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <h1>{{current_EAID}} <small class="text-muted">#{{case_id}}</small></h1>
                        </div>
                    </b-card-title>
                    <b-alert show v-bind:variant="warn_box_class_activate">
                        <strong>警告!</strong> {{ warn_box_msg }}
                        <p v-if="expired_at >= 0"> 此人的自证到期时间: {{ (new Date(expired_at * 1000)).toLocaleString() }}</p>
                        <hr>
                        如果对该案件有异议，可以注册账号回复 / 申诉，也可以发送邮件至邮箱: appeal#本站名称。com（# → @， 。→.，本站名称→BFEAC）
                    </b-alert>
                    <b-alert show variant="danger" v-if="report_EAID.toLowerCase() !== current_EAID.toLowerCase()">
                        <strong>警告!</strong> 此人当前的游戏ID和被举报时记录的不同! 被举报时的ID: {{ report_EAID }}
                    </b-alert>
                        <b-overlay :show="history_EAID_loading" rounded="sm">
                    <b-card title="历史ID" fluid="sm" aria-expanded="true" class="mt-3">
                            <div v-if="history_EAID.length > 0">
                                <b-table striped hover
                                         :items="history_EAID"
                                         :fields="[{key:'data', sortable: true, label: '记录日期'}, {key:'change_to', label: 'OriginId'}]"
                                         :no-provider-paging="true" :per-page="perPage" :current-page="currentPage">
                                </b-table>
                                <div class="row" v-if="perPage <= totalRows">
                                    <div class="mx-auto">
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="history_EAID.length === 0 && !history_EAID_loading">
                                <p>未能正确获取到该玩家的历史ID，如果频繁出现该问题，请反馈给管理员！</p>
                            </div>
                    </b-card>
            </b-overlay>
                    <b-card title="举报详情" class="mt-3">
                        <b-card-sub-title class="mb-0">
                            <p>[{{ new Date(report_at).toLocaleString() }}] 举报者: <b-badge variant="info" v-if="reported_by['title']" class="ml-1 custom-badge">{{reported_by['title']}}</b-badge> {{ reported_by['nickname'] }}</p>
                            <p v-if="perm_level() >= 255" >注册邮箱: {{ reported_by["username"] }} | 用户UID： {{ reported_by["uid"] }} | 绑定的橘子OriginId {{ reported_by["origin_userId"] }}</p>
                        </b-card-sub-title>
                        <div>
                            战绩查询 |
                            <a :href="'https://battlefieldtracker.com/bf1/profile/pc/' + this.current_EAID" target="_blank">BTR (BF1)</a> |
                            <a :href="'https://battlefieldtracker.com/bfv/profile/origin/' + this.current_EAID + '/overview'" target="_blank">BTR (BFV)</a>
                        </div>
                        <div v-if="this.snapshot_url !== null">
                            <a :href="this.snapshot_url">战绩快照</a>
                        </div>
                        <b-card-body v-html="report_reason"></b-card-body>
                    </b-card>
                    <b-card title="回复&处理情况" v-show="reply_list.length > 0" class="mt-3">

                        <div v-for="(item, index) in reply_list" :key="index">
                            <b-card :id="'reply_' + item['reply_id']" class="mt-3" >
                                <b-card-title>
                                    <b-badge :variant="case_status_name[item['new_status'].toString()].variant" class="mr-1 custom-badge">{{case_status_name[item['new_status'].toString()].title}}</b-badge>

                                    <b-badge pill variant="warning" v-if="item['operator']['char_type'] == '被举报者'" class="custom-badge">被举报者</b-badge>
                                    <b-badge pill variant="primary" v-else-if="item['operator']['perm_level'] < 16" class="custom-badge">
                                        <b v-if="item['operator']['is_anonymous']">匿名用户</b>
                                        <b v-else>普通用户</b>
                                    </b-badge>
                                    <b-badge pill variant="danger" v-else class="custom-badge">
                                        <b v-if="item['operator']['is_anonymous']">匿名管理员</b>
                                        <b v-else>管理员</b>
                                    </b-badge>
                                    <b-badge variant="info" v-if="item['operator']['title']" class="ml-1 custom-badge">{{item['operator']['title']}}</b-badge>
                                    <b class="nickname-style">{{ item['operator']['nickname'] }}</b>
                                </b-card-title>
                                <b-card-sub-title>
                                    {{ get_second_title(item) }}
                                    <p v-if="perm_level() >= 255">
                                        邮箱 {{ item["operator"]["username"] }} | UID {{ item["operator"]["uid"] }} | OriginId {{ item["operator"]["origin_userId"] }}
                                    </p>
                                </b-card-sub-title>
                                <b-card-text class="mt-3" v-html="item['comment']">
                                </b-card-text>
                                <b-card-footer v-if="perm_level() > 16 && item['new_status'] === -1" bg-variant="">
                                    <b class="btn-link mr-1" v-on:click="set_hide(item['reply_id'])"
                                       v-if="!item['is_hid']">设置为仅管理员可见</b>
                                    <b class="btn-link mr-1" v-on:click="set_un_hide(item['reply_id'])"
                                       v-else>取消仅管理员可见</b>
                                </b-card-footer>
                            </b-card>
                        </div>

                    </b-card>
                    </div>
                </b-card>

                <PageCaseDetailsAdmin v-if="is_login()" :perm_level="perm_level()" :appeal_allowed="appeal" :on_reply_complete="reload_by_reply"/>
                <scroll-button></scroll-button>
            </b-overlay>
            
            <!-- 收藏备注弹窗 -->
            <b-modal id="favour-modal" title="添加收藏备注" @ok="submitFavour" centered no-close-on-backdrop>
                <div class="mobile-friendly-form">
                    <b-form-group label="备注内容 (最多30字)">
                        <b-form-textarea v-model="favourRemind" :max-rows="3" :placeholder="'添加备注（可选）'"></b-form-textarea>
                        <div class="d-flex justify-content-end align-items-center mt-1">
                            <div class="text-muted small mr-2">
                                ({{ favourRemind.length }}/30)
                            </div>
                            <i v-if="favourRemind.length <= 30" class="fas fa-check-circle text-success"></i>
                            <i v-else class="fas fa-times-circle text-danger"></i>
                        </div>
                    </b-form-group>
                </div>
                <template #modal-footer="{ ok, cancel }">
                    <div class="w-100 d-flex justify-content-between">
                        <b-button variant="secondary" @click="cancel()">取消</b-button>
                        <b-button variant="primary" @click="ok()" :disabled="favourRemind.length > 30">确定</b-button>
                    </div>
                </template>
            </b-modal>
            
            <!-- 举报模态框 -->
            <b-modal id="report-modal" title="举报" size="lg" hide-footer>
                <SubmitReport @submit_successful="$bvModal.hide('report-modal')"/>
            </b-modal>
        </b-container>
        
        <!-- 上一条案例悬浮按钮 -->
        <div class="floating-prev-btn" @click="next_case('-case_id')" title="上一条案例">
            <i class="fas fa-chevron-left"></i>
        </div>
        
        <!-- 下一条案例悬浮按钮 -->
        <div class="floating-next-btn" @click="next_case('case_id')" title="下一条案例">
            <i class="fas fa-chevron-right"></i>
        </div>
        
        <!-- 收藏悬浮按钮 -->
        <div v-if="is_login()" class="floating-favour-btn" 
             :class="{'active': isFavourited}" 
             @click="isFavourited ? removeFavour() : addFavour()" 
             :title="isFavourited ? '取消收藏' : '添加收藏'">
            <i class="fas fa-star"></i>
        </div>
        
        <!-- 移动端工具按钮 -->
        <div class="mobile-tools d-md-none">
            <button class="mobile-tools-toggle" @click="showMobileTools = !showMobileTools">
                <i class="fas fa-ellipsis-v"></i>
            </button>
            
            <div class="mobile-tools-menu" :class="{'show': showMobileTools}">
                <!-- 收藏操作 -->
                <button v-if="is_login()" class="mobile-tool-btn"
                        :class="{'active': isFavourited}"
                        @click="mobileAction('favour')">
                    <i class="fas fa-star mr-2"></i> 
                    <span>{{ isFavourited ? '取消收藏' : '添加收藏' }}</span>
                </button>
                
                <!-- 举报操作 -->
                <button class="mobile-tool-btn" @click="showReportModal">
                    <i class="fas fa-flag mr-2"></i> 举报
                </button>
                
                <!-- 导航操作 -->
                <button class="mobile-tool-btn" @click="next_case('-case_id')">
                    <i class="fas fa-chevron-left mr-2"></i> 上一案例
                </button>
                
                <button class="mobile-tool-btn" @click="next_case('case_id')">
                    <i class="fas fa-chevron-right mr-2"></i> 下一案例
                </button>
            </div>
        </div>
    </div>
</template>

<style>
.stamp {
    position: absolute;
    top: 1px; /* 根据需要调整 */
    left: -10px; /* 根据需要调整 */
    transform: rotate(-30deg); /* 给盖章一个倾斜的效果 */
    color: red; /* 盖章的颜色 */
    font-weight: bold; /* 文本加粗 */
    opacity: 0.7; /* 盖章透明度，使其看起来更像是盖在卡片上的 */
    font-size: 20px; /* 调整大小 */
    text-shadow: 2px 2px 2px rgba(0,0,0,0.3); /* 文字阴影，增加立体感 */
}

.custom-badge {
    /* 调整badge的字体大小 */
    font-size: 0.8em;
    /* 调整badge的内边距 */
    padding: .25em .6em;
    /* 可能还需要调整其他样式，如行高、边距等 */
}

.nickname-style {
    /* 调整昵称的字体大小或其他样式以平衡视觉效果 */
    font-size: 0.9em;
    margin-left: 8px; /* 根据需要调整间距 */
}

/* 浮动按钮基础样式 */
.floating-prev-btn,
.floating-next-btn,
.floating-favour-btn {
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1030;
    cursor: pointer;
    transition: all 0.3s ease;
}

.floating-prev-btn:hover,
.floating-next-btn:hover,
.floating-favour-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

.floating-prev-btn i,
.floating-next-btn i,
.floating-favour-btn i {
    font-size: 20px;
}

/* 上一条按钮 */
.floating-prev-btn {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f8f9fa;
    color: #495057;
}

.floating-prev-btn:hover {
    background-color: #e9ecef;
    transform: translateY(-50%) scale(1.05);
}

/* 下一条按钮 */
.floating-next-btn {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f8f9fa;
    color: #495057;
}

.floating-next-btn:hover {
    background-color: #e9ecef;
    transform: translateY(-50%) scale(1.05);
}

/* 收藏按钮 */
.floating-favour-btn {
    right: 20px;
    top: 100px;
    background-color: #f8f9fa;
    color: #6c757d;
}

.floating-favour-btn:hover {
    background-color: #e9ecef;
}

.floating-favour-btn.active {
    color: #ffc107;
}

/* 移动端工具按钮样式 */
.mobile-tools {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1030;
}

.mobile-tools-toggle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all 0.2s ease;
}

.mobile-tools-toggle:hover, .mobile-tools-toggle:focus {
    transform: scale(1.05);
    background-color: #0069d9;
    outline: none;
}

.mobile-tools-menu {
    position: absolute;
    bottom: 60px;
    right: 0;
    background-color: white;
    border-radius: 10px;
    width: 200px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
    display: none;
    flex-direction: column;
    transition: all 0.3s ease;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.mobile-tools-menu.show {
    display: flex;
}

.mobile-tool-btn {
    background: none;
    border: none;
    text-align: left;
    padding: 12px 15px;
    color: #495057;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.mobile-tool-btn:hover, .mobile-tool-btn:focus {
    background-color: #f8f9fa;
    outline: none;
}

.mobile-tool-btn.active {
    color: #ffc107;
}

/* 移动端友好的表单样式 */
@media (max-width: 576px) {
    .mobile-friendly-form {
        font-size: 16px; /* 避免iOS自动放大输入框 */
    }
    
    .mobile-friendly-form textarea {
        font-size: 16px;
    }
    
    /* 确保弹窗适合小屏幕 */
    .modal-dialog {
        margin: 0.5rem;
        max-width: calc(100% - 1rem);
    }
    
    /* 移动端下隐藏桌面按钮 */
    .floating-prev-btn,
    .floating-next-btn,
    .floating-favour-btn {
        display: none;
    }
}

/* 让主内容区域有足够的顶部边距 */
@media (min-width: 768px) {
    .custom-fox.card {
        margin-top: 60px;
    }
}
</style>

<script>
import {get_permlevel, is_login, get_eauid} from '@/cookies'
import PageCaseDetailsAdmin from '@/components/PageCaseDetailsAdmin.vue'
import { request } from '@/api'
import ScrollButton from "@/components/ScrollButton.vue";
import SubmitReport from '@/components/SubmitReport.vue'

export default {
    inject: ['reload'],
    data: function(){
        return {
            reported_by: "",
            current_EAID: "",
            current_status: null,
            report_EAID: "",
            warn_box_class_activate: "alert alert-danger alert-dismissible fade show",
            warn_box_class_list: [
                "primary", // 未处理
                "danger", // 已封禁
                "secondary", // 无效
                "success", // 解封
                "info", // 自证中
                "success" // 刷枪
            ],
            warn_box_msg_list: [
                "该案件暂未处理! 请等待管理员处理本案件!",
                "该案件已经被判定为 [封禁], 这意味着该账号已经被列入BFEAC黑名单, 他将无法加入启用BFEAC黑名单的服务器!",
                "该案件已经被判定为 [无效], 这意味着该玩家目前并未被BFEAC封禁.",
                "该案件的申诉已经被受理, 所以目前我们解封了他的账号.",
                "当事人申请了自证, 故在自证期间给予该状态. 若未按时完成, 将根据情况重新判定.",
                "该案件已经被判定为 [刷枪], 这意味着他的异常数据是由刷枪行为造成的, 故我们解封了他的账号. 如果有其他证据可以联系管理员。"
            ],
            warn_box_msg: null,
            report_reason: null,
            snapshot_url: null,
            history_EAID: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 25],
            reply_list: [],
            perm_level: get_permlevel,
            appeal: false,
            report_at: null,
            loading: true,
            case_id: "-1",
            expired_at: -1,
            history_EAID_loading: true,
            report_originuId: -1,
            case_status_name: {
                "-1": {"variant": "info", "title": "回复"},
                "1": {"variant": "danger", "title": "封禁"},
                "2": {"variant": "secondary", "title": "无效举报"},
                "3": {"variant": "success", "title": "自证通过"},
                "4": {"variant": "success", "title": "等待自证完成"},
                "5": {"variant": "secondary", "title": "刷枪"},
                "0": {"variant": "info", "title": "需要重审"},
                "-100": {"variant": "info", "title": "修改板块"},
                "-101": {"variant": "danger", "title": "申诉"}
            },
            isFavourited: false,
            favourRemind: "",
            favourCount: 0,
            showMobileTools: false
        }
    },
    methods: {
        next_case(direction) {
            request('GET', `/case/get_next_case?current_case_id=${this.case_id}&direction=${direction}&status=${this.current_status}&return_all_info=false`)
                    .then(response => {
                        if (response['have_next_case']) {
                            this.$router.push({name: 'case', params: {id: response['case_id']}})
                        } else {
                            this.$bvModal.msgBoxOk("没有下一个了哦！")
                        }
                    }).catch(e => {
                        this.$bvModal.msgBoxOk(e, {title: '警告!'})
                    })

        },
        reload_by_reply() {
            this.get_case_details(this.$route.params.id)
            document.getElementById("reply_" + this.data.reply_list[this.data.reply_list.length-1]['reply_id']);
        },
        get_history_id(personaId) {
            this.history_EAID_loading = true
            request('GET', '/utils/history_EAID/pid/' + personaId)
                .then(res => {
                    this.history_EAID = res
                    this.totalRows = this.history_EAID.length
                })
                .catch(() => {
                    this.history_EAID = []
                    this.totalRows = 0
                }).finally(
                () => {
                    this.history_EAID_loading = false
                }
            )
        },
        parse_data(data) {
            this.current_EAID = data['target']['current_name']
            this.report_EAID = data['target']['report_name']
            this.report_originuId = data['target']['user_Id']
            this.current_status = data.current_status
            this.warn_box_class_activate = this.warn_box_class_list[data.current_status]
            this.warn_box_msg = this.warn_box_msg_list[data.current_status]
            this.report_reason = data['reason']
            this.reported_by = data["operator"]
            if (data["operator"]["is_anonymous"]){
                this.reported_by['nickname'] = "匿名用户 #" + data['operator']['nickname']
            }
            this.appeal = data['target']['user_Id'] === get_eauid() && data.current_status === 1
            this.reply_list = data['reply']
            this.report_at = data['created_at']
            this.get_history_id(data['target']['personaId'])
            this.snapshot_url = data['battlog_snapshot']
            this.expired_at = data['appeal_expired_at']
            
            // 使用API返回的is_favour参数判断是否已收藏
            if (is_login() && data.hasOwnProperty('is_favour')) {
                this.isFavourited = data['is_favour'] === true;
            }
            
            // 获取收藏数量
            if (is_login()) {
                this.getFavourCount();
            }
        },
        get_case_details(case_id) {
            this.loading = true
            request('GET', '/case/' + case_id)
                .then(data => {
                    this.parse_data(data)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
                .finally(() => {
                    this.loading = false
                    if (this.$route.params.reply_id !== null) {
                        document.getElementById("reply_" + this.$route.params.reply_id);
                    }
                })
        },
        is_login,
        get_second_title(data) {
            let date = new Date(data['time']).toLocaleString()
            let text = "[" + date + "] ";
            if (data['is_hid']) {
                text = "[该回复仅管理员可见] " + text
            }
            return text
        },
        get_comment_title(data) {
            if (data['operator']['is_anonymous']) {
                return data['operator']['nickname']
            }
            return data['operator']['nickname']
        },
        set_hide(reply_id) {
            request('GET', '/case/reply/' + reply_id + '/hide')
                .then(() => {
                    this.$bvModal.msgBoxOk('设置成功')
                    this.get_case_details(this.$route.params.id)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
        },
        set_un_hide(reply_id) {
            request('GET', '/case/reply/' + reply_id + '/un_hide')
                .then(() => {
                    this.$bvModal.msgBoxOk('解除成功')
                    this.get_case_details(this.$route.params.id)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
        },
        getFavourCount() {
            if (!is_login()) return;
            
  
            request('GET', '/user/self/favour/num')
                .then(response => {
                 
                    if (response.num) {
                        this.favourCount = response.num;
                    } else {
                        this.favourCount = 0;
                    }
                })
                .catch(e => {
                    console.error("获取收藏数量失败", e);
                    this.favourCount = 0;
                });
        },
        
        addFavour() {
            if (!is_login()) {
                this.$bvModal.msgBoxOk("请先登录", {title: '提示'});
                return;
            }
            
            if (this.favourCount >= 100) {
                this.$bvModal.msgBoxOk("收藏数量已达上限(100)，请删除一些收藏后再试", {title: '提示'});
                return;
            }
            
            this.favourRemind = "";
            this.$bvModal.show('favour-modal');
        },
        
        submitFavour() {
            if (this.favourRemind.length > 30) {
                return;
            }
            
            request('POST', '/user/self/favour/add_favour', {
                case_id: this.case_id,
                remind: this.favourRemind
            })
                .then(() => {
                    this.isFavourited = true;
                    this.$bvToast.toast('收藏成功', {
                        title: '成功',
                        variant: 'success',
                        solid: true
                    });
                    this.getFavourCount();
                })
                .catch(e => {
                    this.$bvToast.toast(`收藏失败: ${e}`, {
                        title: '错误',
                        variant: 'danger',
                        solid: true
                    });
                });
        },
        
        removeFavour() {
            request('POST', '/user/self/favour/remove_favour', {
                case_id: this.case_id,
                remind: ""
            })
                .then(() => {
                    this.isFavourited = false;
                    this.$bvToast.toast('取消收藏成功', {
                        title: '成功',
                        variant: 'success',
                        solid: true
                    });
                    this.getFavourCount();
                })
                .catch(e => {
                    this.$bvToast.toast(`取消收藏失败: ${e}`, {
                        title: '错误',
                        variant: 'danger',
                        solid: true
                    });
                });
        },
        
        // 移动端工具操作
        mobileAction(action) {
            this.showMobileTools = false;
            
            switch(action) {
                case 'favour':
                    if (this.isFavourited) {
                        this.removeFavour();
                    } else {
                        this.addFavour();
                    }
                    break;
                default:
                    break;
            }
        },
        
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.showMobileTools = false;
        },
        
        scrollToBottom() {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            this.showMobileTools = false;
        },
        
        showReportModal() {
            this.$bvModal.show('report-modal');
            this.showMobileTools = false;
        },
        
        setupTouchNavigation() {
            if (window.innerWidth <= 768) {
                let startX = 0;
                const MIN_SWIPE_DISTANCE = 100;
                
                document.addEventListener('touchstart', (e) => {
                    startX = e.touches[0].clientX;
                });
                
                document.addEventListener('touchend', (e) => {
                    const endX = e.changedTouches[0].clientX;
                    const diffX = endX - startX;
                    
                    if (Math.abs(diffX) >= MIN_SWIPE_DISTANCE) {
                        if (diffX > 0) {
                            this.next_case('-case_id');
                        } else {
                            this.next_case('case_id');
                        }
                    }
                });
            }
        }
    },
    computed: {
        validateRemind() {
            return this.favourRemind.length <= 30;
        }
    },
    activated() {
        if (this.case_id !== this.$route.params.id) {
            this.case_id = this.$route.params.id
            this.get_case_details(this.$route.params.id)
        } else {
            if (this.$route.params.reply_id !== null) {
                document.getElementById("reply_" + this.$route.params.reply_id).scrollIntoView();
            }
        }
    },
    mounted() {
        this.setupTouchNavigation();
    },
    watch: {
        '$route' (to, from) {
            if (to.params.id && from.params.id && to.params.id !== from.params.id) {
                this.case_id = this.$route.params.id
                this.get_case_details(this.$route.params.id)
            }
        }
    },
    components: {
        ScrollButton,
        PageCaseDetailsAdmin,
        SubmitReport
    }
}
</script>